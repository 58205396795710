.active-sales-codes-tile {
  position: relative;
}

.active-sales-code-menulist {
  right: 0;
  min-width: 15rem;
  margin-right: 2rem;
}

table.active-sales-codes-table {
  position: relative;
}

table.active-sales-codes-table.relax-table > thead > tr > th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

table.active-sales-codes-table.relax-table > tbody > tr > td:first-of-type {
  text-transform: uppercase;
}

td.not-applicable {
  font-style: italic;
  color: #bbb;
}
