.person-page-main {
  width: 100%;
  flex-grow: 1;
}

.person-page-main > section.relax-mesh {
  grid-template-rows: none;
}

.bank-accounttype-radiogroup {
  margin-right: 1rem;
}

.bank-accounttype-label {
  padding-top: 0.2rem !important;
  padding-bottom: 0.25rem !important;
}

.bank-accounttype-container {
  display: flex;
  flex-flow: row wrap;
}
