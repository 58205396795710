.copy-cost-center .relax-modal-close {
  display: none;
}

.copy-cost-center .loader-visible {
  visibility: visible;
}

.copy-cost-center .loader-hidden {
  visibility: hidden;
}

.copy-cost-center .table-container {
  max-height: 250px;
  overflow: auto;
}

.copy-cost-center .table-container table.relax-table > thead > tr > th {
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.copy-cost-center
  .table-container
  table.relax-table
  tr:nth-of-type(odd)
  input:enabled {
  background-color: #fff;
}

.copy-cost-center .table-container table.relax-table .relax-form-group {
  margin: 0;
}

.copy-cost-center .checkbox-validation-container input {
  display: none;
}
