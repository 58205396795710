.edit-bank-account-modal {
  width: 40rem;
  min-height: 25rem;
}

.edit-bank-account-modal .relax-dl.info-detail {
  margin-bottom: 1rem;
}

.edit-bank-account-modal .relax-col.notification-container {
  margin-bottom: 1rem;
}
