.notification-container {
  z-index: 10;
}

.notification-container-inline {
  position: relative;
  height: 0;
  overflow: visible;
  width: 100%;
}
