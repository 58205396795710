.date-range-field {
  display: block !important;
}

.date-range-arrow {
  display: block !important;
  margin-left: 3.9em;
}

.date-range-clear-button {
  display: block !important;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
}

.date-range-container {
  position: relative;
}
