.relax-card-header-title {
  display: flex;
  align-items: center;
}

span.relax-card-header-title:after {
  content: none;
}

.relax-card-header--fill {
  height: 100%;
  width: 100%;
  min-height: 87px;
}

.link,
.link:visited {
  margin-left: 3px;
  color: #333;
}

.link:hover {
  text-decoration: underline;
}

/* Relax override */
.search-page .relax-results > li:hover,
.search-page .relax-results > li:hover dd,
.search-page .relax-results > li:hover .search-page-result-text,
.search-page .relax-results > li:hover dt,
.search-page .relax-results > li:hover table {
  color: #ffffff;
}

.relax-results {
  margin: 0.5rem;
}

.relax-global-search-title {
  margin-bottom: 0;
}

/*
.search-result-table dl {
  flex-basis: 25%;
}

.search-result-table dl:first-child {
  width: 12rem;
}
*/

.search-result-list .relax-grid {
  width: 100%;
  margin-bottom: auto;
}

.search-result-list > li {
  background-position-y: 2.5rem;
  background-position-x: 1.5rem;
  padding-left: 3rem !important;
}

.search-result-item-table > * {
  min-width: auto;
  margin: 0;
  align-self: flex-start;
}

.search-result-item-table > dl > dt {
  margin: auto;
}

.search-result-item-table > dl > dd {
  word-wrap: break-word;
}

.search-result-item-table .sales-id-text {
  text-transform: uppercase;
}

.search-result-item-table .sales-code-text {
  text-transform: uppercase;
}

.search-result-item-table > dl:first-child > dd {
  font-weight: bold;
}

.search-result-list-item {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Esingle 01%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%23332A26%27 stroke=%27%23332A26%27%3E%3Cpath data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 d=%27M30,26.587 c0-1.684-1.045-3.188-2.629-3.76C25.044,21.986,21.206,21,16,21s-9.044,0.986-11.371,1.827C3.045,23.399,2,24.903,2,26.587V31h28 V26.587z%27/%3E%3Cpath fill=%27none%27 stroke=%27%23332A26%27 stroke-miterlimit=%2710%27 d=%27M16,17L16,17 c-3.866,0-7-3.134-7-7V8c0-3.866,3.134-7,7-7h0c3.866,0,7,3.134,7,7v2C23,13.866,19.866,17,16,17z%27/%3E%3C/g%3E%3C/svg%3E') !important;
  background-size: 1rem !important;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}

.search-result-list-item:hover {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Esingle 01%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%23F4F4F4%27 stroke=%27%23F4F4F4%27%3E%3Cpath data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 d=%27M30,26.587 c0-1.684-1.045-3.188-2.629-3.76C25.044,21.986,21.206,21,16,21s-9.044,0.986-11.371,1.827C3.045,23.399,2,24.903,2,26.587V31h28 V26.587z%27/%3E%3Cpath fill=%27none%27 stroke=%27%23F4F4F4%27 stroke-miterlimit=%2710%27 d=%27M16,17L16,17 c-3.866,0-7-3.134-7-7V8c0-3.866,3.134-7,7-7h0c3.866,0,7,3.134,7,7v2C23,13.866,19.866,17,16,17z%27/%3E%3C/g%3E%3C/svg%3E') !important;
}

.search-result-list-item:hover .edit-user-icon-button {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Eedit%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%23F4F4F4%27 stroke=%27%23F4F4F4%27%3E%3Cline data-color=%27color-2%27 x1=%273%27 y1=%2731%27 x2=%2729%27 y2=%2731%27 fill=%27none%27 stroke-miterlimit=%2710%27/%3E%3Cline data-color=%27color-2%27 x1=%2719%27 y1=%276%27 x2=%2724%27 y2=%2711%27 fill=%27none%27 stroke-miterlimit=%2710%27/%3E%3Cpolygon points=%2711 24 4 26 6 19 23 2 28 7 11 24%27 fill=%27none%27 stroke=%27%23F4F4F4%27 stroke-miterlimit=%2710%27/%3E%3C/g%3E%3C/svg%3E');
}

.search-result-list-item:hover .add-user-icon-button {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Ea add%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%23F4F4F4%27 stroke=%27%23F4F4F4%27%3E%3Cpath fill=%27none%27 stroke=%27%23F4F4F4%27 stroke-miterlimit=%2710%27 d=%27M19,20.334 C17.549,20.129,15.886,20,14,20c-5.57,0-9.247,1.165-11.227,2.043C1.69,22.524,1,23.598,1,24.783V30h22%27/%3E%3Cpath fill=%27none%27 stroke=%27%23F4F4F4%27 stroke-miterlimit=%2710%27 d=%27M7,8c0-3.866,3.134-7,7-7 s7,3.134,7,7s-3.134,8-7,8S7,11.866,7,8z%27/%3E%3Cline data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 x1=%2727%27 y1=%2719%27 x2=%2727%27 y2=%2727%27/%3E%3Cline data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 x1=%2723%27 y1=%2723%27 x2=%2731%27 y2=%2723%27/%3E%3C/g%3E%3C/svg%3E%0A');
}

.edit-user-icon-button {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg class='nc-icon-wrapper' stroke-linecap='square' stroke-width='1.5' fill='none' stroke='%23332A26' stroke-miterlimit='10'%3E%3Cpath data-color='color-2' d='M3 31h26M19 6l5 5'/%3E%3Cpath d='M11 24l-7 2 2-7L23 2l5 5-17 17z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.add-user-icon-button {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Ea add%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%23332A26%27 stroke=%27%23332A26%27%3E%3Cpath fill=%27none%27 stroke=%27%23332A26%27 stroke-miterlimit=%2710%27 d=%27M19,20.334 C17.549,20.129,15.886,20,14,20c-5.57,0-9.247,1.165-11.227,2.043C1.69,22.524,1,23.598,1,24.783V30h22%27/%3E%3Cpath fill=%27none%27 stroke=%27%23332A26%27 stroke-miterlimit=%2710%27 d=%27M7,8c0-3.866,3.134-7,7-7 s7,3.134,7,7s-3.134,8-7,8S7,11.866,7,8z%27/%3E%3Cline data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 x1=%2727%27 y1=%2719%27 x2=%2727%27 y2=%2727%27/%3E%3Cline data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 x1=%2723%27 y1=%2723%27 x2=%2731%27 y2=%2723%27/%3E%3C/g%3E%3C/svg%3E%0A');
  background-repeat: no-repeat;
}

.add-user-icon-button-container {
  float: right;
}

.search-result-list-item-with-adduser-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Esingle 01%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%23F4F4F4%27 stroke=%27%23F4F4F4%27%3E%3Cpath data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 d=%27M30,26.587 c0-1.684-1.045-3.188-2.629-3.76C25.044,21.986,21.206,21,16,21s-9.044,0.986-11.371,1.827C3.045,23.399,2,24.903,2,26.587V31h28 V26.587z%27/%3E%3Cpath fill=%27none%27 stroke=%27%23F4F4F4%27 stroke-miterlimit=%2710%27 d=%27M16,17L16,17 c-3.866,0-7-3.134-7-7V8c0-3.866,3.134-7,7-7h0c3.866,0,7,3.134,7,7v2C23,13.866,19.866,17,16,17z%27/%3E%3C/g%3E%3C/svg%3E') !important;
  background-size: 1rem !important;
  background-color: #143694;
  color: #ffffff;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}

.search-result-list-item-with-adduser-icon dd,
.search-result-list-item-with-adduser-icon dt,
.search-result-list-item-with-adduser-icon dl {
  color: #fff;
}

.search-result-list-item-with-adduser-icon .add-user-icon-button {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Ea add%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%23F4F4F4%27 stroke=%27%23F4F4F4%27%3E%3Cpath fill=%27none%27 stroke=%27%23F4F4F4%27 stroke-miterlimit=%2710%27 d=%27M19,20.334 C17.549,20.129,15.886,20,14,20c-5.57,0-9.247,1.165-11.227,2.043C1.69,22.524,1,23.598,1,24.783V30h22%27/%3E%3Cpath fill=%27none%27 stroke=%27%23F4F4F4%27 stroke-miterlimit=%2710%27 d=%27M7,8c0-3.866,3.134-7,7-7 s7,3.134,7,7s-3.134,8-7,8S7,11.866,7,8z%27/%3E%3Cline data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 x1=%2727%27 y1=%2719%27 x2=%2727%27 y2=%2727%27/%3E%3Cline data-color=%27color-2%27 fill=%27none%27 stroke-miterlimit=%2710%27 x1=%2723%27 y1=%2723%27 x2=%2731%27 y2=%2723%27/%3E%3C/g%3E%3C/svg%3E%0A');
}
