dl.relax-dl.info-detail {
  word-break: break-all;
  margin: 0;
}

dl.relax-dl.info-detail > dt {
  margin: 0;
}

dl.relax-dl.info-detail > dd {
  min-height: 0;
}

dl.relax-dl.info-detail > dd > span {
  display: inline-block;
}
