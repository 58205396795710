.customer-drop-down .search-dropdown-field {
  max-width: 20rem;
}

.dropdown-menu .add-customer-li {
  height: auto;
}

.dropdown-menu .add-customer-container {
  padding: 1rem 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.dropdown-menu .add-customer-container .add-customer-text {
  flex: 0 0 100%;
}

.dropdown-menu .add-customer-container .add-customer-column {
  flex-grow: 1;
  padding-right: 1rem;
  font-size: 0.875rem;
}
