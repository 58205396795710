.salescodeconversion-page {
  padding: 2rem;
  height: 100%;
  background-color: white;
}

.salescodeconversion-page .statistics-table {
  width: 100%;
}

.salescodeconversion-page .width-5 {
  width: 5rem;
}

.salescodeconversion-page .width-10 {
  width: 10rem;
}

.salescodeconversion-page .width-15 {
  width: 15rem;
}

.salescodeconversion-page .width-18 {
  width: 18rem;
}

.salescodeconversion-page .statistics-table > thead > tr > th {
  background-color: #f4f4f4;
  outline: none;
  border: none;
  border-bottom: 0.125rem solid hsla(0, 0%, 74.5%, 0.2);
}
.salescodeconversion-page .salescodes-conversions-table {
  position: relative;
}

.salescodeconversion-page .salescodes-conversions-table > tbody > tr > th {
  position: sticky;
}

.salescodeconversion-page .menulist {
  right: 0;
  min-width: 15rem;
  margin-right: 2rem;
}

div.salescodes-conversions-table-container > div {
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
}

.background-white {
  background-color: white !important;
}

.background-green {
  background-color: #d0e6c3 !important;
}
