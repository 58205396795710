.bank-account-history-modal {
  height: 55rem;
  width: 80rem;
}

.bank-account-history-modal table.relax-table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.bank-account-history-modal .relax-modal-content {
  padding-top: 0;
}

.bank-account-history-modal .relax-loader {
  margin-top: 5rem;
}

.bank-account-history-modal .relax-table {
  overflow-wrap: break-word;
}
