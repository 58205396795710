div.icon-with-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.icon-with-button.icon-with-button-align-top {
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

div.icon-with-button.icon-with-button-align-center {
  justify-content: center;
  height: 100%;
  padding-bottom: 8rem;
}
