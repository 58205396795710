.list-icon-in-tile {
  padding-top: 8rem;
  background-size: 4rem;
  background-position: center;
}

.historical-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 8rem;
}

table.historical-sales-codes-table.relax-table > thead > tr > th {
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
}

table.historical-sales-codes-table > tbody > tr > td:first-of-type {
  text-transform: uppercase;
}
