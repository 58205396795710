.search-dropdown-field-menu {
  z-index: 110;
  background-color: white;
  border: 1px solid 848484;
}

.rbt {
  width: 100%;
}

.rbt-input-main {
  width: 100%;
  max-width: 100% !important;
  cursor: default;
}

.rbt-input-main:focus {
  cursor: text;
}

.rbt-input-hint-container {
  overflow: hidden;
}
.rbt-aux {
  right: 1.5rem;
}

.rbt-input-hint-container input {
  border: none;
  max-width: 100%;
  width: 100%;
}
.rbt.clearfix.open {
  width: 100%;
}
.rbt-input-hint {
  display: none !important;
}
.sr-only.rbt-sr-status {
  display: none !important;
}
.search-dropdown-field-menu li {
  padding-left: 1rem;
  padding-right: 1rem;
}
.search-dropdown-field-menu li a,
.search-dropdown-field-menu li a:visited {
  color: black;
}
.search-dropdown-field-menu li:hover a,
.search-dropdown-field-menu li:hover a:visited {
  color: white;
}
.search-dropdown-field-menu li:hover:before {
  display: none;
}
.search-dropdown-field-menu-header {
  color: #848484;
  padding: 1rem;
}
a.dropdown-item {
  width: 100%;
  height: 100%;
  display: flex;
}
.search-result-item {
  padding: 0 0.5rem;
  margin: auto 0;
}
a.dropdown-item .search-result-item-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
a.dropdown-item .search-result-item-col {
  display: flex;
  width: 12rem;
  word-wrap: break-word;
}

div.search-dropdown-field .dropdown-value-text {
  position: absolute;
  top: 0.4rem;
  right: 2rem;
  z-index: 10;
  pointer-events: none;
}

fieldset.dropdown-block {
  display: block;
}
