.sales-code-details .relax-modal-close {
  display: none;
}

.sales-code-details div.loader-visible {
  visibility: visible;
}

.sales-code-details div.loader-hidden {
  visibility: hidden;
}

.sales-code-details div.errormessage {
  color: red;
}

.sales-code-details div.errormessage-visible {
  visibility: visible;
}

.sales-code-details div.errormessage-hidden {
  visibility: hidden;
}
