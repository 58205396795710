div.ReactModalPortal:focus {
  outline: none !important;
}

/* Needed to avoid buttons floating over the feedback component - should be fixed in Relax? */
.relax-button {
  z-index: inherit;
}

.clickable:hover {
  cursor: pointer;
}

/* Find a better way to override this? */
body div a {
  text-decoration: none;
}

body div a:hover {
  text-decoration: underline;
}

.frontpage-logo {
  width: 7rem;
  margin: 2rem auto 0.5rem;
  height: 5rem;
  background-size: contain;
  background-image: url('data:image/svg+xml,%3Csvg fill=%27%23333333%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 189 128.5%27%3E%3Cstyle%3E.st0%7Bfill:%23004278;%7D .st1%7Bfill:%23068AC6;%7D%3C/style%3E%3Cpath d=%27M100.3 51.5h-6.7c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h6.7c-1-2-1.5-4.2-1.5-6.5s.5-4.5 1.5-6.5z%27/%3E%3Cpath d=%27M107.1 71.3v13.1c0 9.4-7.6 17-17 17s-17-7.6-17-17V58c0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5v26.5c0 9.4-7.6 17-17 17s-17-7.6-17-17V58c0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5v26.5c0 16.5 13.5 30 30 30 9.5 0 18-4.5 23.5-11.4 5.5 6.9 14 11.4 23.5 11.4 16.5 0 30-13.5 30-30V71.3c-2 1-4.2 1.5-6.5 1.5s-4.5-.5-6.5-1.5zM151 12h-37.4c-3.6 0-6.5 2.9-6.5 6.5v26.2c2-1 4.2-1.5 6.5-1.5s4.5.6 6.5 1.5V25H151c7.3 0 13.3 6 13.3 13.3 0 7.3-6 13.3-13.3 13.3h-24.1c1 2 1.5 4.2 1.5 6.5s-.6 4.5-1.5 6.5H151c14.5 0 26.3-11.8 26.3-26.3 0-14.6-11.8-26.3-26.3-26.3z%27/%3E%3Ccircle class=%27st1%27 cx=%27113.6%27 cy=%2758%27 r=%276.5%27/%3E%3C/svg%3E%0A');
  background-repeat: no-repeat;
}

.relax-theme--dark .frontpage-logo {
  background-image: url('data:image/svg+xml,%3Csvg fill=%27%23bebebe%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 189 128.5%27%3E%3Cstyle%3E.st0%7Bfill:%23004278;%7D .st1%7Bfill:%23068AC6;%7D%3C/style%3E%3Cpath d=%27M100.3 51.5h-6.7c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h6.7c-1-2-1.5-4.2-1.5-6.5s.5-4.5 1.5-6.5z%27/%3E%3Cpath d=%27M107.1 71.3v13.1c0 9.4-7.6 17-17 17s-17-7.6-17-17V58c0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5v26.5c0 9.4-7.6 17-17 17s-17-7.6-17-17V58c0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5v26.5c0 16.5 13.5 30 30 30 9.5 0 18-4.5 23.5-11.4 5.5 6.9 14 11.4 23.5 11.4 16.5 0 30-13.5 30-30V71.3c-2 1-4.2 1.5-6.5 1.5s-4.5-.5-6.5-1.5zM151 12h-37.4c-3.6 0-6.5 2.9-6.5 6.5v26.2c2-1 4.2-1.5 6.5-1.5s4.5.6 6.5 1.5V25H151c7.3 0 13.3 6 13.3 13.3 0 7.3-6 13.3-13.3 13.3h-24.1c1 2 1.5 4.2 1.5 6.5s-.6 4.5-1.5 6.5H151c14.5 0 26.3-11.8 26.3-26.3 0-14.6-11.8-26.3-26.3-26.3z%27/%3E%3Ccircle class=%27st1%27 cx=%27113.6%27 cy=%2758%27 r=%276.5%27/%3E%3C/svg%3E%0A');
  background-repeat: no-repeat;
}

.relax-search-result-list > li > header > a {
  color: inherit;
}

.relax-lightGrey {
  background-color: #f4f4f4 !important;
}

.salescode-tile-menu {
  float: right;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle r='3' cy='4' cx='16' stroke-miterlimit='10' fill='none' stroke='%23332A26' stroke-width='1.5' stroke-linecap='square'/%3E%3Ccircle r='3' cy='16' cx='16' stroke-miterlimit='10' data-color='color-2' fill='none' stroke='%23332A26' stroke-width='1.5' stroke-linecap='square'/%3E%3Ccircle r='3' cy='28' cx='16' stroke-miterlimit='10' fill='none' stroke='%23332A26' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E");
  height: 1.5rem;
  width: 1.5rem;
  border: none;
  padding: 0;
  margin: 0;
  background-size: 1.5rem;
  background-color: initial;
}

.salescode-tile-menu:hover {
  transform: scale(1.5);
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.relax-table > tbody > tr > * {
  vertical-align: inherit;
}

main.relax-main {
  flex-direction: column;
}

header.relax-global-header {
  justify-content: center;
  background-color: #c1bfbd;
}

header.relax-global-header a:visited {
  color: currentColor;
}

nav.relax-menubar {
  z-index: 9;
}

.input-salesid {
  max-width: 10rem;
  text-transform: uppercase;
}

dl.salesid-info-detail > dd {
  text-transform: uppercase;
}

.input-name {
  max-width: 20rem;
  width: 20rem;
}

.none-decoration {
  text-decoration: none !important;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.relax-menubar.relax-right .relax-menubar-action-button {
  display: none;
}

.relax-menubar.relax-right #toggle-settings-drawer-button {
  display: block;
}

.relax-breadcrumbs {
  color: #473f3b;
}

.relax-breadcrumbs > li:before {
  color: #473f3b;
}
