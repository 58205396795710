.error-header-row {
  margin-left: 20px;
}

a:visited.error-action-button,
button.error-action-button {
  color: inherit !important;
}

.list-actions {
  list-style: none;
  margin: 0;
  margin-top: auto;
}
