.bsc-container input {
  max-width: 15rem;
}

.bsc-container input:read-only {
  background-color: #d9d9d9;
}

.bsc-container h3 {
  margin: 1rem 0;
}

.bsc-container .bsc-customer-dropdown-container {
  margin-bottom: 2rem;
}

.bsc-container .bsc-customer-dropdown-container .bsc-customer-dropdown-label {
  display: block;
  margin: 0.5rem 0;
}

.bsc-container .bsc-columns-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.bsc-container .bsc-columns-outer-container {
  min-height: 25.3125rem;
}

.bsc-container .bsc-columns-container .bsc-column {
  margin-right: 3rem;
}

.bsc-container .bsc-columns-container .bsc-column-no-header {
  margin-top: 1.25rem;
  margin-left: -2rem;
}

.bsc-container .bsc-columns-container h3:first-of-type {
  margin-top: 0;
}

.bsc-container .notification-container {
  margin-bottom: 1rem;
}

.bsc-container .relax-modal-content {
  min-height: 42.5rem;
  min-width: 35rem;
}

.bsc-container .bsc-buttons-container {
  display: flex;
  flex-direction: row;
}
