.search-logo-wrapper {
  margin: 0 auto;
  width: 96px;
  height: 96px;
}
.logo-help-wrapper {
  margin: 0 auto;
  text-align: center;
  height: auto;
  margin-bottom: 20px;
}

.search-result {
  text-align: center;
  margin-top: 10px;
}

.relax-search-result-list {
  margin: 0 !important;
}

.relax-icon-ui-add-user:hover {
  transform: none;
}

.relax-results-container {
  max-width: none;
}

.relax-global-search-title {
  padding-left: 1rem;
}

.no-transition {
  transition: none;
}

.no-transform:hover {
  transform: none;
}

.search-controller {
  max-width: 100%;
}

.search-container {
  max-width: 100% !important;
}
