.MTableToolbar-root-23 {
  min-height: 0 !important;
}

.MuiSelect-select {
  background-color: #f4f4f4 !important;
}

.MTableHeader-header-31 {
  z-index: 8 !important;
}
