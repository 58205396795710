.address-search-container {
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
}

.address-search-row {
  padding: 10px 0;
  border-bottom: 1px solid;
}

.address-search-row:hover {
  background-color: #d9d9d9;
}

.address-search-input {
  margin-top: 20px;
  margin-bottom: 5px;
}
