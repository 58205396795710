#addressForm {
  min-width: 500px;
  max-width: 500px;
}

#addressForm input[type='text'] {
  max-width: 100%;
  padding: 0 10px;
}

#addressForm label {
  display: block;
}

.max-width {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.title {
  position: absolute;
  top: 10px;
  left: 10px;
}

.link-button {
  color: #113eba;
  width: 6rem;
  border: none;
  background-color: transparent;
  margin-bottom: 0;
}

.link-button:hover {
  cursor: pointer;
}

.divider-line {
  border-bottom: solid;
  margin: 10px 0;
}

.address-update-button {
  min-width: 100%;
  max-width: 100%;
}
