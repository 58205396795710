.personIcon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath clip-rule='evenodd' fill='none' stroke-miterlimit='10' d='M26.588 23.518l-5.177-1.035A3 3 0 0119 19.541v-1.414c2.363-1.414 4-4.336 4-7.127V7.074c0-1.925-1.568-3.5-3.501-3.5h-.436l-.919-1.837a.96.96 0 00-1.201-.465l-5.505 2.202C10.09 4.013 9 5.625 9 7.076V11c0 2.791 1.637 5.713 4 7.127v1.414a3 3 0 01-2.412 2.942l-5.177 1.035A2.999 2.999 0 003 26.459V31h26v-4.541a2.998 2.998 0 00-2.412-2.941z' stroke-linecap='square' stroke-width='1.5' stroke='%23332A26'/%3E%3C/svg%3E");
  background-size: 3rem;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-right: 30px;
}

.person-info-main {
  width: 100%;
  max-width: 100%;
  margin: 0 1rem 0 2rem;
}

.person-info-sub {
  display: flex;
  align-items: center;
}

div.person-buttons-container {
  display: flex;
  flex-direction: row;
  margin: 2rem auto;
}
