.broker-commission-history-container {
  background-color: #f1ece8;
  margin-top: 2rem;
}

.broker-commission-history-container .break-word {
  word-wrap: break-word;
}

.broker-commission-history-container h3 {
  font-size: 1.125rem;
  font-weight: normal;
  margin: 0;
  padding: 0.5rem 1rem;
}

.broker-commission-history-container .table-container {
  padding: 0.125rem;
  position: relative;
}

.broker-commission-history-container .table-container .loading-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 0.875rem;
}

.broker-commission-history-container .broker-commission-history-table {
  margin: 0;
}

.broker-commission-history-table > tbody > tr.yellow {
  background-color: rgba(255, 255, 0, 0.2);
}

.broker-commission-history-table .width-auto {
  width: auto;
}

.broker-commission-history-table .width-6 {
  width: 6rem;
}

.broker-commission-history-table .width-7-375 {
  width: 7.375rem;
}

.broker-commission-history-table .width-8 {
  width: 8rem;
}

.broker-commission-history-table .width-10 {
  width: 10rem;
}
