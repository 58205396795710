.commission-page {
  padding: 2rem;
  height: 100%;
  background-color: white;
}

.commission-page .search-container {
  margin-top: 1.5rem;
}

.commission-page h2 {
  font-size: 2rem;
  line-height: 2.5rem;
}

.commission-page th,
.commission-page td {
  vertical-align: top !important;
}

.commission-page .edit-button {
  margin-left: 1rem;
}

.commission-page .results-table {
  min-width: 95rem;
  width: 100%;
}

.commission-page .table-container {
  position: relative;
  overflow: auto;
  padding: 0 0.0625rem;
}

.commission-page .table-container .loading-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 2rem;
}

.commission-page tr td:last-of-type {
  position: relative;
}

.commission-page tr td:last-of-type .expand-icon {
  position: absolute;
  right: 1rem;
}

.commission-page table.current-details {
  width: 23.2%;
  margin-bottom: 2rem;
}

.commission-page table.current-details th {
  padding: 0.5rem 1rem;
}

.commission-page table.current-details td {
  padding: 0 1rem;
}

.commission-page table.current-details tr,
.commission-page table.current-details tr:hover > * {
  background: none !important;
  border: none !important;
}

.commission-page tr.row-details,
.commission-page tr.row-details:hover > * {
  background-color: #f9f9f9 !important;
}

.commission-page tr.row-details > td {
  padding: 0;
}

.commission-page .expanded-row-container {
  margin: 0.5rem;
}

.commission-page .expanded-row-subcontainer {
  margin: 0 1rem;
}

.commission-page .width-auto {
  width: auto;
}

.commission-page .width-6 {
  width: 6rem;
}

.commission-page .width-8 {
  width: 8rem;
}

.commission-page .width-10 {
  width: 10rem;
}
